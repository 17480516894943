<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12">
            <list-table-data
              :table-columns="tableColumns"
              :table-data="items"
              :redirect-edit="redirectEdit"
              :redirect-show="redirectShow"
              :origen="catalogoName"
              :show-icon-new="true"
              :tipo-catalogo="catalogoType"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import ListTableData from '@/components/ListTableData.vue'
import { getlistado } from '@/utils/catalogos'

export default {
  components: {
    ListTableData,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      catalogoName: 'Unidades Varias',
      catalogoType: 10,
      redirectEdit: 'catalogos-edit-unidades-varias',
      redirectShow: 'catalogos-edit-unidades-varias',
      items: [],
      tableColumns: [
        {
          label: 'Nombre',
          field: 'nombre',
        },
        {
          label: 'Estado',
          field: 'active',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Acciones',
          field: 'acciones',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
    }
  },
  watch: {
    $route() {
      this.loadData()
    },
  },
  async beforeMount() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      try {
        if (this.$route.path.includes('trabajos-levantamiento')) {
          this.catalogoName = 'Trabajos Levantamiento'
          this.catalogoType = 30
          this.redirectEdit = 'catalogos-edit-trabajos-levantamiento'
          this.redirectShow = 'catalogos-edit-trabajos-levantamiento'
          this.tableColumns.splice(1, 0, {
            label: 'Tipo Trabajo',
            field: 'tipoTrabajo',
          })
        } else {
          this.catalogoName = 'Unidades Varias'
          this.catalogoType = 10
          this.redirectEdit = 'catalogos-edit-unidades-varias'
          this.redirectShow = 'catalogos-edit-unidades-varias'
          const tipoTrabajoIndex = this.tableColumns.findIndex(column => column.field === 'tipoTrabajo')
          if (tipoTrabajoIndex !== -1) {
            this.tableColumns.splice(tipoTrabajoIndex, 1)
          }
        }

        const user = JSON.parse(localStorage.getItem('userData'))

        this.items = await getlistado(
          this.catalogoType,
          this.catalogoName,
          true,
          user,
        )
        if (this.items === null) this.items = []
      } catch (err) {
        console.error(`Error en cargar ${this.catalogoName}`, err)
        this.items = []
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
